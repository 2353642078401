import CenterPanel from '../../ui/widgets/CenterPanel';
import useEnObserve from '../../tools/hooks/endpoints/useEnObserve';
import { Typography, IconButton, Grid, Button } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import { Trans, useTranslation } from 'react-i18next';
import { useMemo, useLayoutEffect } from 'react'
import Box from '../../tools/Components/atoms/Box';
import api from '../../endpoints';
import usePathRouter from '../../tools/hooks/paths/usePathRouter';
import LeftRight from '../../tools/Components/atoms/LeftRight';
import PathLink from '../../tools/Components/paths/PathLink';
import usePathParams from '../../tools/hooks/paths/usePathParams';
import {
  downloadTourExcel, 
  getTourStatus, 
  priceToStr, 
  tourDaysInfo, 
} from '../../utils/helpers';
import { local } from '../../tools/libs/paths/helpers';
import Icon from '../../ui/widgets/Icon';
import EditIcon from '@mui/icons-material/Edit';
import TelegramIcon from '@mui/icons-material/Telegram';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArchiveIcon from '@mui/icons-material/Archive';
import TourStatus from '../../ui/widgets/TourStatus';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { getDateFormat1 } from '../../tools/utils/moment';
import ShowOnEvent from '../../tools/Components/event/ShowOnEvent';
import Options from '../../ui/widgets/Options';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Excel from '../../icons/Excel';
import useBoolean from '../../tools/hooks/useBoolean';
import ArchiveTour from './Modals/ArchiveTour';
import DeleteTour from './Modals/DeleteTour';
import Loadable from '../../ui/widgets/Loadable';
import PathMatch from '../../tools/Components/paths/PathMatch';
import ReservesTablePage from './Reserves/ReservesTablePage';
import usePathMatch from '../../tools/hooks/paths/usePathMatch';
import ChairIcon from '@mui/icons-material/Chair';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import WaitsTablePage from './Waits/WaitsTablePage';
import CancelsTablePage from './Cancels/CancelsTablePage';

const TourLeftPage = () => {
  const { t } = useTranslation()
  
  const [router] = usePathRouter()

  const [{ 
    tourId 
  }] = usePathParams(router.root.c.tours.c.one)

  const toursOneMatchExact = usePathMatch(router.root.c.tours.c.one, true)

  const [archiveTourOpened, , , archiveTourOpen, archiveTourClose] = useBoolean()
  const [deleteTourOpened, , , deleteTourOpen, deleteTourClose] = useBoolean()
  
  const [{ data: tour, isLoading }] = useEnObserve(api.tree.tours.one, tourId)
  const [{ data: tourTourists }] = useEnObserve(api.tree.reserves.list, tourId)

  const { startMoment, endMoment, diffDays, daysBefore } = tour ? tourDaysInfo(tour) : {}

  const remainder = tour?.price - tour?.prepayment

  const reservedTourists = useMemo(
    () => (tourTourists || [])
      .filter(({ status_payment }) => ['prepayment', 'full'].indexOf(status_payment) > -1),
    [tourTourists]
  )

  const cancelTourists = useMemo(
    () => (tourTourists || [])
      .filter(({ status_payment }) => ['cancellation', 'transfer'].indexOf(status_payment) > -1),
    [tourTourists]
  )

  const waitTourists = useMemo(
    () => (tourTourists || [])
      .filter(({ status_payment }) => ['wait_list'].indexOf(status_payment) > -1),
    [tourTourists]
  )

  const tourOptions = useMemo(
    () => [
      {
        icon: (<Icon Component={EditIcon} />),
        text: t('Common.edit'),
        href: router.getUrl([
          [router.root.c.editTour.c.one, local({ tourId })]
        ])
      },
      {
        icon: (<Icon Component={TelegramIcon} />),
        text: t('Tour.tgNotify.start'),
        href: `https://t.me/tfs_notify_bot?start=mail_${tourId}`,
        target: '_blank'
      },
      {
        icon: (<Icon Component={Excel} />),
        text: t('Tour.excel.download'),
        onClick: () => downloadTourExcel(tour)
      },
      {
        icon: (<Icon Component={ArchiveIcon} color='error' />),
        text: t('Common.archive'),
        color: 'error',
        onClick: archiveTourOpen
      },
      {
        icon: (<Icon Component={DeleteForeverIcon} color='error' />),
        text: t('Common.delete'),
        color: 'error',
        onClick: deleteTourOpen
      }
    ],
    [t, tourId, tour, router, archiveTourOpen, deleteTourOpen]
  )

  useLayoutEffect(
    () => {
      if(!toursOneMatchExact) {
        return
      }

      router.replaceUrl(router.root.c.tours.c.one.c.reserves.getUrl())
    },
    [router, toursOneMatchExact]
  )

  return (
    <CenterPanel>
      <ArchiveTour
        id={tourId}
        opened={archiveTourOpened}
        onClose={archiveTourClose}
      />
      <DeleteTour
        id={tourId}
        opened={deleteTourOpened}
        onClose={deleteTourClose}
      />
      <Loadable
        loading={!tourTourists && isLoading}
        empty={!tour || !tourTourists}
      >
        {() => (
          <Linely
            vertical
            gap='i1'
          >
            <LeftRight>
              <Linely
                gap={0}
                vertical
              >
                <Linely gap={0}>
                  <Box ml='-i2'>
                    <PathLink
                      href={router.root.c.tours.getUrl()}
                    >
                      <IconButton color='inherit'>
                        <Icon
                          big
                          Component={ChevronLeftIcon}
                        />
                      </IconButton>
                    </PathLink>
                  </Box>
                  <Linely>
                    <TourStatus
                      tour={tour}
                    />
                    <Typography variant='h6'>
                      {tour.title}
                    </Typography>
                  </Linely>
                </Linely>
                <Typography color='textSecondary'>
                  <Typography display='inline' color='textPrimary' variant='subtitle1'>
                    {getDateFormat1(startMoment)}
                  </Typography>
                  {' - '}
                  <Typography display='inline' color='textPrimary' variant='subtitle1'>
                    {getDateFormat1(endMoment)}
                  </Typography>
                  {'. '}
                  (
                    <Trans
                      i18nKey='Tour.dates.diffDays.transformed'
                      values={{ count: diffDays }}
                      components={[
                        <Typography key={0} display='inline' color='textPrimary' variant='subtitle1' />
                      ]}
                    />
                    {daysBefore > 0 && (
                      <>
                        {', '}
                        <Trans
                          i18nKey='Tour.dates.daysBefore.transformed'
                          values={{ count: daysBefore }}
                          components={[
                            <Typography key={0} display='inline' color='textPrimary' variant='subtitle1' />
                          ]}
                        />
                      </>
                    )}
                  )
                </Typography>
                <Typography variant='subtitle1'>
                  {priceToStr(tour.price)}{' '}
                  <Typography display='inline' variant='body1' color='textSecondary'>
                    (<Trans
                      i18nKey='Tour.prepayment.ofFull.transformed'
                      values={{ prepayment: tour.prepayment, remainder }}
                      components={[
                        <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />
                      ]}
                    />)
                  </Typography>
                </Typography>
              </Linely>
              <ShowOnEvent
                event='click'
                Component={Options}
                options={tourOptions}
              >
                <IconButton>
                  <Icon
                    Component={MoreVertIcon}
                  />
                </IconButton>
              </ShowOnEvent>
            </LeftRight>
            <Grid
              container
              spacing={1}
              alignItems='center'
              sx={{
                maxWidth: 500
              }}
            >
              <Grid item xs={4}>
                <PathLink
                  href={router.root.c.tours.c.one.c.reserves.getUrl()}
                >
                  <Button
                    fullWidth
                    color={router.root.c.tours.c.one.c.reserves.isMatch ? 'primary' : 'inherit'}
                  >
                    <Linely
                      vertical
                      centered
                    >
                      <Icon
                        big
                        Component={ChairIcon}
                      />
                      <LeftRight
                        centered
                      >
                        <Typography
                          variant="body2"
                        >
                          {t('Tourist.toursInfo.coming')}
                        </Typography>
                        <Typography variant="body2">({reservedTourists.length})</Typography>
                      </LeftRight>
                    </Linely>
                  </Button>
                </PathLink>
              </Grid>
              {(getTourStatus(tour).isComing || waitTourists.length > 0) && (
                <Grid item xs={4}>
                  <PathLink
                    href={router.root.c.tours.c.one.c.waits.getUrl()}
                  >
                    <Button
                      fullWidth
                      color={router.root.c.tours.c.one.c.waits.isMatch ? 'primary' : 'inherit'}
                    >
                      <Linely
                        vertical
                        centered
                      >
                        <Icon
                          big
                          Component={HourglassFullIcon}
                        />
                        <LeftRight
                          centered
                        >
                          <Typography
                            variant="body2"
                          >
                            {t('Tourist.toursInfo.waiting')}
                          </Typography>
                          <Typography variant="body2">({waitTourists.length})</Typography>
                        </LeftRight>
                      </Linely>
                    </Button>
                  </PathLink>
                </Grid>
              )}
              {cancelTourists.length > 0 && (
                <Grid item xs={4}>
                  <PathLink
                    href={router.root.c.tours.c.one.c.cancels.getUrl()}
                  >
                    <Button
                      fullWidth
                      color={router.root.c.tours.c.one.c.cancels.isMatch ? 'primary' : 'inherit'}
                    >
                      <Linely
                        vertical
                        centered
                      >
                        <Icon
                          big
                          Component={DisabledByDefaultIcon}
                        />
                        <LeftRight
                          centered
                        >
                          <Typography
                            variant="body2"
                          >
                            {t('Tourist.toursInfo.canceled')}
                          </Typography>
                          <Typography variant="body2">({cancelTourists.length})</Typography>
                        </LeftRight>
                      </Linely>
                    </Button>
                  </PathLink>
                </Grid>
              )}
            </Grid>
            <PathMatch path={router.root.c.tours.c.one.c.reserves} Component={ReservesTablePage} />
            <PathMatch path={router.root.c.tours.c.one.c.waits} Component={WaitsTablePage} />
            <PathMatch path={router.root.c.tours.c.one.c.cancels} Component={CancelsTablePage} />
          </Linely>
        )}
      </Loadable>
    </CenterPanel>
  )
}

export default TourLeftPage
