import useEnObserve from '../../tools/hooks/endpoints/useEnObserve';
import api from '../../endpoints';
import usePathParams from '../../tools/hooks/paths/usePathParams';
import RightPanel from '../../ui/widgets/RightPanel';
import Linely from '../../tools/Components/atoms/Linely';
import LeftRight from '../../tools/Components/atoms/LeftRight';
import { Link, Typography } from '@mui/material';
import { DASH_LINE } from '../../tools/utils/string';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import TourStatus from '../../ui/widgets/TourStatus';
import { getTourStatus, getTourStatusLocaleKey, priceToStr, userToFullFio } from '../../utils/helpers';
import { tsToMomentMoscow } from '../../utils/moment';
import { getDateFormat1 } from '../../tools/utils/moment';
import Box from '../../tools/Components/atoms/Box';
import Loadable from '../../ui/widgets/Loadable';

const TourPage = () => {
  const { t } = useTranslation()

  const [{ tourId }] = usePathParams()
  
  const [{ data: tour, isLoading }] = useEnObserve(api.tree.tours.one, tourId)

  const startMoment = tour && tsToMomentMoscow(tour.start_date)
  const endMoment = tour && tsToMomentMoscow(tour.end_date)

  const diffDays = useMemo(
    () => {
      if(!startMoment || !endMoment) {
        return 0
      }

      return endMoment.diff(startMoment, 'days') + 1
    },
    [endMoment, startMoment]
  )

  const remainder = tour?.price - tour?.prepayment

  return (
    <RightPanel>
      <Loadable
        loading={!tour && isLoading}
        empty={!tour}
      >
        {() => (
          <Linely
            fulLine
            vertical
            gap='i1'
          >
            <LeftRight>
              <Typography
                variant='subtitle1'
              >
                {t('Tour.one.title')}
              </Typography>
            </LeftRight>
            <Linely
              vertical
              gap='i4'
            >
              <LeftRight>
                <Typography color='textSecondary'>{t('Common.name')}</Typography>
                <Typography
                  align='right'
                  style={{
                    maxWidth: '70%',
                  }}
                >
                  {tour.title || DASH_LINE}
                </Typography>
              </LeftRight>
              <LeftRight>
                <Typography color='textSecondary'>{t('Tour.dates.title')}</Typography>
                <Typography>
                  {getDateFormat1(startMoment)} - {getDateFormat1(endMoment)}{' '}
                  <Typography display='inline' color='textSecondary'>
                    ({t('Tour.dates.diffDays', { count: diffDays })})
                  </Typography>
                </Typography>
              </LeftRight>
              <LeftRight>
                <Typography color='textSecondary'>{t('Tour.status')}</Typography>
                <Linely>
                  <TourStatus noTooltip tour={tour} />
                  <Typography>{t(getTourStatusLocaleKey(getTourStatus(tour)))}</Typography>
                </Linely>
              </LeftRight>
              <LeftRight>
                <Typography color='textSecondary'>{t('Tour.places.busy')}</Typography>
                <Typography>
                  {t('Common.countOf', { count: tour.busy_places, total: tour.total_places })}
                </Typography>
              </LeftRight>
              <LeftRight>
                <Typography color='textSecondary'>{t('Common.price')}</Typography>
                <Box>
                  <Typography align='right'>
                    {priceToStr(tour.price)}{' '}
                  </Typography>
                  <Typography align='right' color='textSecondary'>
                    {t('Tour.prepayment.prepayment', { prepayment: tour.prepayment })}
                  </Typography>
                  <Typography align='right' color='textSecondary'>
                    {t('Tour.prepayment.remainder', { remainder })}
                  </Typography>
                </Box>
              </LeftRight>
              <LeftRight>
                <Typography color='textSecondary'>{t('Common.link')}</Typography>
                {tour.site_link
                  ? (
                    <Link
                      href={tour.site_link}
                      target='_blank'
                      align='right'
                      style={{
                        maxWidth: '70%',
                        wordBreak: 'break-all',
                      }}
                    >
                      {tour.site_link}
                    </Link>
                  )
                  : (
                    <Typography>
                      {DASH_LINE}
                    </Typography>
                  )
                }
              </LeftRight>
              <LeftRight>
                <Typography color='textSecondary'>{t('Tour.manager.title')}</Typography>
                <Typography>
                  {tour.manager ? userToFullFio(tour.manager) : DASH_LINE}
                </Typography>
              </LeftRight>
              <LeftRight>
                <Typography color='textSecondary'>{t('Tour.escort.title')}</Typography>
                <Typography>
                  {tour.escort ? userToFullFio(tour.escort) : DASH_LINE}
                </Typography>
              </LeftRight>
              <LeftRight>
                <Typography color='textSecondary'>{t('Common.comment')}</Typography>
                <Typography
                  align='right'
                  style={{
                    maxWidth: '70%',
                  }}
                >
                  {tour.comment || DASH_LINE}
                </Typography>
              </LeftRight>
              <LeftRight>
                <Typography color='textSecondary'>Ссылка на старую excel таблицу</Typography>
                {tour.info_link 
                  ? (
                    <Link
                      href={tour.info_link}
                      target='_blank'
                      align='right'
                      style={{
                        maxWidth: '70%',
                        wordBreak: 'break-all',
                      }}
                    >
                      {tour.info_link}
                    </Link>
                  )
                  : (
                    <Typography align='right' color='textSecondary'>
                      {DASH_LINE}
                    </Typography>
                  )
                }
              </LeftRight>
            </Linely>
          </Linely>
        )}
      </Loadable>
    </RightPanel>
  )
}

export default TourPage
