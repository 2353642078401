import {
  Box,
  Popover,
  List,
  ListItem,
  ListItemButton,
  Typography,
  Divider
} from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely'
import PathLink from '../../tools/Components/paths/PathLink'
import Childer from '../../tools/Components/Childer'

export const OptionsListItem = ({
  onClose,
  onClick,
  href,
  target,
  children,
  icon,
  text,
  variant,
  color,
  ...props
}) => {
  const ListItemComponent = (onClick || href) ? ListItemButton : ListItem

  return (
    <PathLink
      onClick={(e) => { onClick?.(e); onClose?.() }}
      href={href}
      target={target}
    >
      <ListItemComponent
        color={color}
        {...props}
      >
        <Box>
          {children ?? (
            <Linely gap='i4'>
              {icon}
              {children || (
                <Typography color={color || 'inherit'} variant={variant || 'body1'}>
                  {text}
                </Typography>
              )}
            </Linely>
          )}
        </Box>
      </ListItemComponent>
    </PathLink>
  )
}

function Options ({
  onClose,
  options,
  anchorEl,
  opened,
  ...props
}) {
  return (
    <Childer
      need
      parrent={children => (
        <Popover
          {...props}
          open={opened}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center'
          }}
        >
          {children}
        </Popover>
      )}
    >
      <List
        onMouseUp={() => setTimeout(onClose, 10)}
      >
        {options
          .filter(el => el)
          .map((props, i) => props.isDivider
            ? (
              <Divider key={i} />
              )
            : (
              <OptionsListItem
                key={i}
                text={props.text}
                {...props}
              />
              ))}
      </List>
    </Childer>
  )
}

export default Options
