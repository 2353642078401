import CenterPanel from '../../ui/widgets/CenterPanel';
import useEnObserve from '../../tools/hooks/endpoints/useEnObserve';
import { Typography, Badge } from '@mui/material'
import Linely from '../../tools/Components/atoms/Linely';
import { Trans, useTranslation } from 'react-i18next';
import { SortableTable } from '../../ui/tables';
import { useMemo, useState, useCallback } from 'react'
import Box from '../../tools/Components/atoms/Box';
import FilledSearch from '../../ui/widgets/FilledSearch';
import api from '../../endpoints';
import usePathRouter from '../../tools/hooks/paths/usePathRouter';
import AddIcon from '@mui/icons-material/Add'
import TelegramIcon from '@mui/icons-material/Telegram';
import LeftRight from '../../tools/Components/atoms/LeftRight';
import CircleButton from '../../ui/widgets/CircleButton';
import PathLink from '../../tools/Components/paths/PathLink';
import RightPanel from '../../ui/widgets/RightPanel';
import Icon from '../../ui/widgets/Icon';
import { 
  connectToElementsPhrasesByRussian, 
  downloadTourExcel, 
  filterSeasonsNotSelected, 
  getTourStatus, 
  seasonToDates, 
  sortedIsAsc, 
} from '../../utils/helpers';
import ActionRenderer, { optionsRendererColumn } from '../../ui/tableRenderers/ActionRenderer';
import Options from '../../ui/widgets/Options';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArchiveIcon from '@mui/icons-material/Archive';
import { local, remove } from '../../tools/libs/paths/helpers';
import Excel from '../../icons/Excel';
import ArchiveTour from './Modals/ArchiveTour';
import DeleteTour from './Modals/DeleteTour';
import { useModal } from '../../hooks/useModal';
import { tsToMomentMoscow } from '../../utils/moment';
import { usePercentWidth } from '../../hooks/usePercentWidth';
import FilterListIcon from '@mui/icons-material/FilterList';
import ShowOnEvent from '../../tools/Components/event/ShowOnEvent';
import moment from 'moment';
import usePathParams from '../../tools/hooks/paths/usePathParams';
import Loadable from '../../ui/widgets/Loadable';
import { merge } from 'lodash';
import SortableTableCleareAll from '../../ui/tables/SortableTable/SortableTableCleareAll';
import { TourNameRenderer, tourNameSearcher } from '../../ui/tableRenderers/TourNameRenderer';
import { TourSeasonsFilter } from '../../ui/TableFilters/TourSeasonsFilter';
import { TourDatesRenderer } from '../../ui/tableRenderers/TourDatesRenderer';
import { TourPlacesRenderer } from '../../ui/tableRenderers/TourPlacesRenderer';
import { TourPriceRenderer } from '../../ui/tableRenderers/TourPriceRenderer';
import { TourEscortManagerFilter } from '../../ui/TableFilters/TourEscortManagerFilter';
import { TourLinkRenderer } from '../../ui/tableRenderers/TourLinkRenderer';
import { TourManagerRenderer } from '../../ui/tableRenderers/TourManagerRenderer';
import { TourEscortRenderer } from '../../ui/tableRenderers/TourEscortRenderer';
import { TourTotalFilter } from '../../ui/TableFilters/TourTotalFilter';
import { ToursArchiveFilter } from '../../ui/TableFilters/ToursArchiveFilter';

const ToursList = () => {
  const { t } = useTranslation()

  const [router] = usePathRouter()

  const [{ tSearch: { value: tSearch } }] = merge(usePathParams(router.root.c.tours.c.tSearch.c.value), [{ tSearch: {} }])
  const [{ tSorted: { value: tSorted } }] = merge(usePathParams(router.root.c.tours.c.tSorted.c.value), [{ tSorted: {} }])
  const [{ tSeasons: { value: tSeasons } }] = merge(usePathParams(router.root.c.tours.c.tSeasons.c.value), [{ tSeasons: {} }])
  const [{ tComing: { value: tComing } }] = merge(usePathParams(router.root.c.tours.c.tComing.c.value), [{ tComing: {} }])
  const [{ tCustomDatesStart: { value: tCustomDatesStart } }] = merge(usePathParams(router.root.c.tours.c.tCustomDatesStart.c.value), [{ tCustomDatesStart: {} }])
  const [{ tCustomDatesEnd: { value: tCustomDatesEnd } }] = merge(usePathParams(router.root.c.tours.c.tCustomDatesEnd.c.value), [{ tCustomDatesEnd: {} }])
  const [{ tCustomDates: { value: tCustomDates } }] = merge(usePathParams(router.root.c.tours.c.tCustomDates.c.value), [{ tCustomDates: {} }])
  const [{ tOver: { value: tOver } }] = merge(usePathParams(router.root.c.tours.c.tOver.c.value), [{ tOver: {} }])
  const [{ tCurrent: { value: tCurrent } }] = merge(usePathParams(router.root.c.tours.c.tCurrent.c.value), [{ tCurrent: {} }])
  const [{ tBusy: { value: filterTotal } }] = merge(usePathParams(router.root.c.tours.c.tBusy.c.value), [{ tBusy: {} }])
  const [{ tManagers: { value: tManagers } }] = merge(usePathParams(router.root.c.tours.c.tManagers.c.value), [{ tManagers: {} }])
  const [{ tEscorts: { value: tEscorts } }] = merge(usePathParams(router.root.c.tours.c.tEscorts.c.value), [{ tEscorts: {} }])
  const [{ tArchive: { value: filterArchive } }] = merge(usePathParams(router.root.c.tours.c.tArchive.c.value), [{ tArchive: {} }])
  const [{ tPage: { value: tPage } }] = merge(usePathParams(router.root.c.tours.c.tPage.c.value), [{ tPage: {} }])

  const searchValue = (tSearch ?? '').toString()

  const [optionsElement, setOptionsElement] = useState()
  
  const pageWidth = usePercentWidth()

  const [archiveTourModal, deleteTourOpen] = useModal(DeleteTour)
  const [deleteTourModal, archiveTourOpen] = useModal(ArchiveTour)
  
  const [{ data: tours, isLoading }] = useEnObserve(api.tree.tours.list)

  const totalTours = (tours || []).length

  const lastTours = useMemo(
    () => (tours || [])
      .filter(tour => getTourStatus(tour).isOver)
      .length,
    [tours]
  )

  const comingTours = useMemo(
    () => (tours || [])
      .filter(tour => getTourStatus(tour).isComing)
      .length,
    [tours]
  )

  const curentTours = useMemo(
    () => (tours || [])
      .filter(tour => {
        const { isComing, isOver } = getTourStatus(tour)
        return !isComing && !isOver
      })
      .length,
    [tours]
  )

  const archivedTours = useMemo(
    () => (tours || [])
      .filter(({ archived }) => archived)
      .length,
    [tours]
  )

  const sorter = useMemo(
    () => {
      const [name, ascDesc] = tSorted?.split(',') || []

      return {
        name,
        asc: ascDesc === 'asc'
      }
    },
    [tSorted]
  )

  const filterManager = useMemo(
    () => (tManagers && typeof tManagers !== 'boolean') ? tManagers.toString().split(',').map(Number) : [],
    [tManagers]
  )

  const filterEscort = useMemo(
    () => (tEscorts && typeof tEscorts !== 'boolean') ? tEscorts.toString().split(',').map(Number) : [],
    [tEscorts]
  )

  const filterSeasons = useMemo(
    () => ({
      coming: !!tComing,
      over: !!tOver,
      current: !!tCurrent,
      seasons: Object.fromEntries(
        (!!tSeasons ? tSeasons.split(',') : [])
          .map(key => [key, true])
      ),
      customDatesEnd: tCustomDatesEnd && moment.unix(tCustomDatesEnd),
      customDatesStart: tCustomDatesStart && moment.unix(tCustomDatesStart),
      customDates: !!tCustomDates,
    }),
    [
      tComing, 
      tOver, 
      tCurrent, 
      tSeasons, 
      tCustomDatesEnd, 
      tCustomDatesStart, 
      tCustomDates
    ]
  )

  const seasonsOfFilterSeasons = useMemo(
    () => Object.entries(filterSeasons.seasons || {})
      .filter(([key, val]) => !!val)
      .map(([key]) => {
        const [year, season] = key.split('_').map(Number)

        return seasonToDates(year, season)
      }),
    [filterSeasons]
  )

  const filteredTours = useMemo(
    () => (tours || [])
      .filter(
        tour => 
          (
            (!!filterArchive === tour.archived)
          ) &&
          (
            !filterTotal || 
            (filterTotal === 'full' && tour.busy_places === tour.total_places) ||
            (filterTotal === 'persent60' && tour.busy_places >= tour.total_places * 0.6 && tour.busy_places !== tour.total_places) ||
            (filterTotal === 'persent50' && tour.busy_places >= tour.total_places * 0.4 && tour.busy_places < tour.total_places * 0.6) ||
            (filterTotal === 'persent40' && tour.busy_places < tour.total_places * 0.4)
          ) &&
          (
            filterManager.length === 0 ||
            (tour.manager && filterManager.indexOf(tour.manager.id) > -1)
          ) &&
          (
            filterEscort.length === 0 ||
            (tour.escort && filterEscort.indexOf(tour.escort.id) > -1)
          ) 
      )
      .filter(tour => {
        const { isOver, isComing } = getTourStatus(tour)

        const momentStartDate = tsToMomentMoscow(tour.start_date)
        const momentEndDate = tsToMomentMoscow(tour.end_date)

        return (
          filterSeasonsNotSelected(filterSeasons) ||
          (filterSeasons.current && !isOver && !isComing) ||
          (filterSeasons.coming && isComing) ||
          (filterSeasons.over && isOver) ||
          (
            filterSeasons.customDates && 
            (!filterSeasons.customDatesStart || momentEndDate.isSameOrAfter(filterSeasons.customDatesStart)) &&
            (!filterSeasons.customDatesEnd || filterSeasons.customDatesEnd.isSameOrAfter(momentStartDate))
          ) ||
          seasonsOfFilterSeasons.some(([startDate, endDate]) => 
            momentEndDate.isSameOrAfter(startDate) &&
            endDate.isSameOrAfter(momentStartDate)
          )
        )
      }),
    [
      tours,
      filterEscort,
      filterManager,
      filterTotal,
      seasonsOfFilterSeasons,
      filterSeasons,
      filterArchive
    ]
  )

  const sortedTours = useMemo(
    () => [
      ...filteredTours.sort((a, b) => {
        if(sorter.name === 'name') {
          return sortedIsAsc(sorter.asc, a.title > b.title)
        }
        else if(sorter.name === 'dates') {
          return sortedIsAsc(sorter.asc, a.start_date > b.start_date)
        }
        else if(sorter.name === 'total') {
          return sortedIsAsc(sorter.asc, a.total_places > b.total_places)
        }
        else if(sorter.name === 'price') {
          return sortedIsAsc(sorter.asc, a.price > b.price)
        }

        return tsToMomentMoscow(a.start_date).isBefore(tsToMomentMoscow(b.start_date)) ? 1 : -1
      })
    ],
    [filteredTours, sorter]
  )

  const filterSeasonsIsActive = useMemo(
    () => !filterSeasonsNotSelected(filterSeasons),
    [filterSeasons]
  )

  const onSetSearchValue = useCallback(
    (search) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.tSearch.c.value, search ? local({ value: search }) : remove()],
      [router.root.c.tours.c.tPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetSorter = useCallback(
    ({ name, asc }) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.tSorted.c.value, local({ value: `${name},${asc ? 'asc' : 'desc'}` })],
      [router.root.c.tours.c.tPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFilterEscort = useCallback(
    (escorts) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.tEscorts.c.value, escorts.length > 0 ? local({ value: escorts.join(',') }) : remove()],
      [router.root.c.tours.c.tPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFilterManager = useCallback(
    (managers) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.tManagers.c.value, managers.length > 0 ? local({ value: managers.join(',') }) : remove()],
      [router.root.c.tours.c.tPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFilterTotal = useCallback(
    (tBusy) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.tBusy.c.value, tBusy ? local({ value: tBusy }) : remove()],
      [router.root.c.tours.c.tPage.c.value, remove()],
    ])),
    [router]
  )

  const onSetFilterSeasons = useCallback(
    ({
      coming, 
      over, 
      current, 
      seasons, 
      customDatesEnd, 
      customDatesStart, 
      customDates
    }) => {
      const realSeasons = Object.entries(seasons)
        .filter(([, val]) => val)
        .map(([key]) => key)

      router.pushUrl(router.getUrl([
        [router.root.c.tours.c.tComing.c.value, coming ? local({ value: coming }) : remove()],
        [router.root.c.tours.c.tOver.c.value, over ? local({ value: over }) : remove()],
        [router.root.c.tours.c.tCurrent.c.value, current ? local({ value: current }) : remove()],
        [router.root.c.tours.c.tCustomDates.c.value, customDates ? local({ value: customDates }) : remove()],
        [router.root.c.tours.c.tSeasons.c.value, realSeasons.length > 0 ? local({ value: realSeasons.join(',') }) : remove()],
        [router.root.c.tours.c.tCustomDatesEnd.c.value, customDatesEnd ? local({ value: customDatesEnd?.unix?.() }) : remove()],
        [router.root.c.tours.c.tCustomDatesStart.c.value, customDatesStart ? local({ value: customDatesStart?.unix?.() }) : remove()],
        [router.root.c.tours.c.tPage.c.value, remove()],
      ]))
    },
    [router]
  )

  const onSetFilterArchive = useCallback(
    (tArchive) => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.tArchive.c.value, tArchive ? local({ value: tArchive }) : remove()],
      [router.root.c.tours.c.tPage.c.value, remove()],
    ])),
    [router]
  )

  const onClearAllFilters = useCallback(
    () => router.pushUrl(router.getUrl([
      [router.root.c.tours.c.tSorted.c.value, remove()],

      [router.root.c.tours.c.tArchive.c.value, remove()],
      [router.root.c.tours.c.tArchive.c.value, remove()],
      [router.root.c.tours.c.tArchive.c.value, remove()],
      [router.root.c.tours.c.tArchive.c.value, remove()],
      [router.root.c.tours.c.tArchive.c.value, remove()],

      [router.root.c.tours.c.tEscorts.c.value, remove()],

      [router.root.c.tours.c.tManagers.c.value, remove()],
      
      [router.root.c.tours.c.tBusy.c.value, remove()],
      
      [router.root.c.tours.c.tComing.c.value, remove()],
      [router.root.c.tours.c.tOver.c.value, remove()],
      [router.root.c.tours.c.tCurrent.c.value, remove()],
      [router.root.c.tours.c.tCustomDates.c.value, remove()],
      [router.root.c.tours.c.tSeasons.c.value, remove()],
      [router.root.c.tours.c.tCustomDatesEnd.c.value, remove()],
      [router.root.c.tours.c.tCustomDatesStart.c.value, remove()],

      [router.root.c.tours.c.tArchive.c.value, remove()],

      [router.root.c.tours.c.tPage.c.value, remove()],
    ])),
    [router]
  )

  const tourOptions = useCallback(
    (tour) => [
      {
        icon: (<Icon Component={EditIcon} />),
        text: t('Common.edit'),
        href: router.getUrl([
          [router.root.c.editTour.c.one, local({ tourId: tour.id })]
        ])
      },
      {
        icon: (<Icon Component={TelegramIcon} />),
        text: t('Tour.tgNotify.start'),
        href: `https://t.me/tfs_notify_bot?start=mail_${tour.id}`,
        target: '_blank'
      },
      {
        icon: (<Icon Component={Excel} />),
        text: t('Tour.excel.download'),
        onClick: () => downloadTourExcel(tour)
      },
      {
        icon: (<Icon Component={ArchiveIcon} color='error' />),
        text: t('Common.archive'),
        color: 'error',
        onClick: () => archiveTourOpen({ id: tour.id })
      },
      {
        icon: (<Icon Component={DeleteForeverIcon} color='error' />),
        text: t('Common.delete'),
        color: 'error',
        onClick: () => deleteTourOpen({ id: tour.id })
      }
    ],
    [t, router, archiveTourOpen, deleteTourOpen]
  )
  
  const columns = useMemo(
    () => [
      {
        id: '',
        sorter: 'name',
        label: t('Common.name'),
        maxWidth: pageWidth * 0.168,
        search: tourNameSearcher,
        renderer: TourNameRenderer
      },
      {
        id: '',
        sorter: 'dates',
        minWidth: 160,
        filter: 'seasons',
        filterIsActive: filterSeasonsIsActive,
        filterComponent: TourSeasonsFilter,
        filterComponentProps: {
          filterState: filterSeasons,
          onChange: onSetFilterSeasons,
          tours,
        },
        label: t('Tour.dates.title'),
        renderer: TourDatesRenderer
      },
      {
        id: '',
        sorter: 'total',
        filter: 'total',
        filterIsActive: filterTotal != null,
        filterComponent: TourTotalFilter,
        filterComponentProps: {
          filterState: filterTotal,
          onChange: onSetFilterTotal,
        },
        label: t('Tour.places.busy'),
        renderer: TourPlacesRenderer
      },
      {
        id: '',
        sorter: 'price',
        label: t('Common.price'),
        renderer: TourPriceRenderer
      },
      {
        id: '',
        label: t('Common.link'),
        renderer: TourLinkRenderer,
        noClick: true
      },
      {
        id: '',
        filter: 'manager',
        filterIsActive: filterManager.length > 0,
        filterComponent: TourEscortManagerFilter,
        filterComponentProps: {
          typeUser: 'manager',
          filterState: filterManager,
          onChange: onSetFilterManager,
        },
        label: t('Tour.manager.title'),
        renderer: TourManagerRenderer,
      },
      {
        id: '',
        filter: 'escort',
        filterIsActive: filterEscort.length > 0,
        filterComponent: TourEscortManagerFilter,
        filterComponentProps: {
          typeUser: 'escort',
          filterState: filterEscort,
          onChange: onSetFilterEscort,
        },
        label: t('Tour.escort.title'),
        renderer: TourEscortRenderer,
      },
      {
        id: '',
        noClick: true,
        columnView: () => (
          <SortableTableCleareAll
            canClear={
              filterSeasonsIsActive ||
              filterTotal != null ||
              filterManager.length > 0 ||
              !!tSorted
            }
            onClear={onClearAllFilters}
          />
        ),
        action: { 
          ...optionsRendererColumn, 
          onClick: (tourist, e) => {
            setOptionsElement(
              <Options
                opened
                onClose={() => setOptionsElement(null)}
                options={tourOptions(tourist)}
                anchorEl={e.target}
              />
            )
          }
        },
        renderer: ActionRenderer
      },
    ],
    [
      t,
      tours,
      tSorted,
      filterSeasons,
      filterManager,
      filterEscort,
      filterTotal,
      filterSeasonsIsActive,
      pageWidth,
      tourOptions,
      onClearAllFilters,
      onSetFilterEscort,
      onSetFilterManager,
      onSetFilterTotal,
      onSetFilterSeasons,
    ]
  )

  const href = useCallback(
    ({ id }) => router.root.c.tours.c.one.getUrl({
      tourId: id
    }),
    [router]
  )

  return (
    <>
      <CenterPanel>
        {archiveTourModal}
        {deleteTourModal}
        {optionsElement}
        <Loadable
          loading={!tours && isLoading}
          empty={!tours}
        >
          {() => (
            <Linely
              vertical
              gap='i1'
            >
              <LeftRight>
                <Linely
                  gap={0}
                  vertical
                >
                  <Typography variant='h6'>
                    {t('Tour.subtitle', { count: totalTours })}
                  </Typography>
                  <Typography color='textSecondary'>
                    {connectToElementsPhrasesByRussian(
                      [
                        !!lastTours && (
                          <Trans
                            i18nKey='Tour.subtitle2.lastTours'
                            values={{ count: lastTours }}
                            components={[
                              <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />
                            ]}
                          />
                        ),
                        !!comingTours && (
                          <Trans
                            i18nKey='Tour.subtitle2.comingTours'
                            values={{ count: comingTours }}
                            components={[
                              <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />
                            ]}
                          />
                        ),
                        !!curentTours && (
                          <Trans
                            i18nKey='Tour.subtitle2.curentTours'
                            values={{ count: curentTours }}
                            components={[
                              <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />
                            ]}
                          />
                        ),
                        !!archivedTours && (
                          <Trans
                            i18nKey='Tour.subtitle2.archivedTours'
                            values={{ count: archivedTours }}
                            components={[
                              <Typography key={0} display='inline' variant='subtitle1' color='textPrimary' />
                            ]}
                          />
                        )
                      ]
                        .filter(el => !!el)
                    )}
                  </Typography>
                </Linely>
                <PathLink
                  href={router.getUrl([
                    [router.root.c.createTour, {}]
                  ])}
                >
                  <CircleButton
                    big
                    variant='contained'
                  >
                    <Icon
                      big
                      Component={AddIcon}
                    />
                  </CircleButton>
                </PathLink>
              </LeftRight>
              <Linely
                gap='i1'
              >
                <FilledSearch
                  value={searchValue}
                  onChange={onSetSearchValue}
                />
                <ShowOnEvent
                  event='click'
                  onChange={onSetFilterArchive}
                  filterState={filterArchive}
                  Component={ToursArchiveFilter}
                >
                  <CircleButton
                    big
                    color='inherit'
                  >
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={!filterArchive}
                    >
                      <Icon
                        big
                        Component={FilterListIcon}
                      />
                    </Badge>
                  </CircleButton>
                </ShowOnEvent>
              </Linely>
              <Box mx='-i1'>
                <SortableTable
                  searchValue={searchValue}
                  columns={columns}
                  data={sortedTours}
                  href={href}
                  sorter={sorter}
                  onSetSorter={onSetSorter}
                  paginationProps={{
                    rowsPerPage: 20,
                    page: tPage ? tPage -1 : 0,
                    onPageChange: (newPage) => 
                      router.pushUrl(router.getUrl([
                        [router.root.c.tours.c.tPage.c.value, local({ value: newPage + 1 })],
                      ]))
                  }}
                />
              </Box>
            </Linely>
          )}
        </Loadable>
      </CenterPanel>
      <RightPanel />
    </>
  )
}

export default ToursList
