import { useTranslation } from 'react-i18next';
import { Grid, Button, Typography, MenuItem } from '@mui/material'
import { Field, useFormikContext } from 'formik';
import Box from '../../../tools/Components/atoms/Box';
import SubmitButtonWrapper from '../../../tools/Components/formik/SubmitButtonWrapper';
import Linely from '../../../tools/Components/atoms/Linely';
import DatePickerField from '../../../ui/formik/DatePickerField';
import { tourDaysInfo, userToFullFio } from '../../../utils/helpers';
import Centered from '../../../tools/Components/atoms/Centered';
import TextField from '../../../ui/formik/TextField';
import useEnObserve from '../../../tools/hooks/endpoints/useEnObserve';
import api from '../../../endpoints';
import SelectField from '../../../ui/formik/SelectField';
import FormRequiredFieldsHelper from '../../../ui/widgets/FormRequiredFieldsHelper';
import Loadable from '../../../ui/widgets/Loadable';

const TourModalForm = ({ lSubmitLable, onClose }) => {
  const { t } = useTranslation()

  const [{ data: managers, isLoadingManagers }] = useEnObserve(api.tree.users.list, 'manager')
  const [{ data: escorts, isLoadingEscorts }] = useEnObserve(api.tree.users.list, 'escort')

  const { values } = useFormikContext()

  const { diffDays } = (values.start_date && values.end_date && tourDaysInfo({
    start_date: values.start_date.unix(),
    end_date: values.end_date.unix()
  })) || {}
  
  const remainder = values.price - values.prepayment

  return (
    <>
      <Box
        px='i1'
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              fullWidth
              required
              name='title'
              label={t('Common.name')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={2.5}>
            <Field
              fullWidth
              required
              name='total_places'
              type='number'
              label={t('Tour.places.total')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name='start_date'
              label={t('Tour.dates.start.title')}
              component={DatePickerField}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    variant='filled'
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              name='end_date'
              label={t('Tour.dates.end.title')}
              component={DatePickerField}
              slots={{
                textField: (params) => (
                  <TextField
                    {...params}
                    required
                    fullWidth
                    variant='filled'
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={1.5}>
            <Centered fullHeight>
              <Typography color='textSecondary'>
                ({t('Tour.dates.diffDays', { count: diffDays || 0 })})
              </Typography>
            </Centered>
          </Grid>
          <Grid item xs={5}>
            <Field
              fullWidth
              required
              name='prepayment'
              type='number'
              label={t('Reserve.status.prepayment')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={7}>
            <Field
              fullWidth
              required
              name='price'
              type='number'
              label={t('Common.price')}
              variant="filled"
              as={TextField}
              InputProps={{
                endAdornment: (
                  <Box
                    sx={{
                      whiteSpace: 'nowrap'
                    }}
                  >
                    <Typography align='right' color='textSecondary'>
                      {t('Tour.prepayment.prepayment', { prepayment: values.prepayment })}
                    </Typography>
                    <Typography align='right' color='textSecondary'>
                      {t('Tour.prepayment.remainder', { remainder })}
                    </Typography>
                  </Box>
                )
              }}
            />
          </Grid>
          <Loadable
            noCentered
            loading={isLoadingManagers}
            empty={!managers}
          >
            {() => (
              <Grid item xs={12}>
                <Field
                  fullWidth
                  required
                  name='responsible_manager'
                  label={t('Tour.manager.title')}
                  variant="filled"
                  as={SelectField}
                >
                  {managers?.sort((a, b) => userToFullFio(a) > userToFullFio(b) ? 1 : -1).map(user => (
                    <MenuItem value={user.id}>
                      <Typography>
                        {userToFullFio(user)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            )}
          </Loadable>
          <Loadable
            noCentered
            loading={isLoadingEscorts}
            empty={!escorts}
          >
            {() => (
              <Grid item xs={12}>
                <Field
                  fullWidth
                  name='responsible_escort'
                  label={t('Tour.escort.title')}
                  variant="filled"
                  as={SelectField}
                >
                  <MenuItem value={null}>
                    <Typography sx={{ fontStyle: 'italic' }}>
                      {t('Common.notSelected')}
                    </Typography>
                  </MenuItem>
                  {escorts?.sort((a, b) => userToFullFio(a) > userToFullFio(b) ? 1 : -1).map(user => (
                    <MenuItem value={user.id}>
                      <Typography>
                        {userToFullFio(user)}
                      </Typography>
                    </MenuItem>
                  ))}
                </Field>
              </Grid>
            )}
          </Loadable>
          <Grid item xs={12}>
            <Field
              fullWidth
              name='site_link'
              label={t('Common.link')}
              variant="filled"
              as={TextField}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              fullWidth
              multiline
              name='comment'
              label={t('Common.comment')}
              variant="filled"
              as={TextField}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        px='i1'
      >
        <FormRequiredFieldsHelper />
      </Box>
      <Linely
        end
        gap='i2'
        px='i1'
      >
        <Button
          variant="contained"
          size='large'
          color='inherit'
          onClick={onClose}
        >
          {t('Common.close')}
        </Button>
        <SubmitButtonWrapper>
          <Button
            variant="contained"
            size='large'
          >
            {t(lSubmitLable)}
          </Button>
        </SubmitButtonWrapper>
      </Linely>
    </>
  )
}

export default TourModalForm
