import { useCallback } from 'react'
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { transformMomentToMomentMoscow } from '../../utils/moment';

const DatePicker = ({ onChange, ...props }) => {
  const handleChange = useCallback(
    moment => {
      // момент может быть либо null, либо другим валидным значением
      if(!!moment && (!moment?.isValid() || moment?.year() < 1000)) {
        return
      }

      onChange(transformMomentToMomentMoscow(moment))
    },
    [onChange]
  )

  return (
    <MuiDatePicker
      {...props}
      onChange={handleChange}
    />
  )
}

export default DatePicker
